<template>
  <div class="incentive_dashboard_wrapper barc">
    <b-container fluid>
      <b-row>
        <b-col sm="12">
          <b-card class="iq-card main_card">
            <b-overlay :show="loading">
              <b-tabs justified>
                <b-tab :title="getOrgName(4)">
                  <div class="org_filter_wrapper">
                    <b-row>
                      <b-col sm="3" class="mb-3">
                          <label for="fiscal_year_id" class="form-label">{{$t('dae_grant_allocation_distribution.fiscal_year')}}</label>
                          <b-form-select
                              plain
                              v-model="search.fiscal_year_id"
                              :options="fiscalYearList"
                              id="fiscal_year_id"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-col>
                      <b-col sm="3" class="mb-3">
                        <div class="btn_filter_wrapper">
                          <button type="submit" class="btn btn-primary" @click="searchData">{{ $t('globalTrans.search') }}</button>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <b-row class="justify-content-md-center barc_card">
                    <b-col sm="3">
                      <b-card class="iq-card shadow_card card-1">
                        <div class="total_card_wrapper">
                          <div class="barc_icon">
                            <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                          </div>
                          <router-link :to="{ path: '/incentive-grant-service/barc/report/grant-application-status-report' }">
                            <div class="content">
                                <h6 class="title">{{ $t('incentDashboard.totalApp') }}</h6>
                                <h2 class="count count_1">{{ $n(data.barc_total_app) }}</h2>
                            </div>
                          </router-link>
                        </div>
                      </b-card>
                    </b-col>
                    <b-col sm="3">
                      <b-card class="iq-card shadow_card card-3">
                        <div class="total_card_wrapper">
                          <div class="barc_icon">
                            <img src="../../../../../assets/images/icon-dashboard/approve.svg" class="img-fluid" alt="">
                          </div>
                          <router-link :to="{ path: '/incentive-grant-service/barc/report/approval-application-report' }">
                            <div class="content">
                                <h6 class="title">{{ $t('incentDashboard.approvedApp') }}</h6>
                                <h2 class="count count_3">{{ $n(data.barc_app_approved) }}</h2>
                            </div>
                          </router-link>
                        </div>
                      </b-card>
                    </b-col>
                    <b-col sm="3">
                      <b-card class="iq-card shadow_card card-2">
                        <div class="total_card_wrapper">
                          <div class="barc_icon">
                            <img src="../../../../../assets/images/icon-dashboard/painding-application.svg" class="img-fluid" alt="">
                          </div>
                          <router-link :to="{ path: '/incentive-grant-service/barc/report/grant-application-status-report', query: {status: 5} }">
                            <div class="content">
                                <h6 class="title">{{ $t('incentDashboard.pendingApp') }}</h6>
                                <h2 class="count count_2">{{ $n(data.barc_app_pending) }}</h2>
                            </div>
                          </router-link>
                        </div>
                      </b-card>
                    </b-col>
                    <b-col sm="3">
                      <b-card class="iq-card shadow_card card-4">
                        <div class="total_card_wrapper">
                          <div class="barc_icon">
                            <img src="../../../../../assets/images/icon-dashboard/rejected-farmer.svg" class="img-fluid" alt="">
                          </div>
                          <router-link :to="{ path: '/incentive-grant-service/barc/report/grant-application-status-report', query: {status: 3} }">
                            <div class="content">
                                <h6 class="title">{{ $t('incentDashboard.rejectApp') }}</h6>
                                <h2 class="count count_4">{{ $n(data.barc_app_rejected) }}</h2>
                            </div>
                          </router-link>
                        </div>
                      </b-card>
                    </b-col>
                    <b-col sm="3">
                      <b-card class="iq-card shadow_card card-1">
                        <div class="total_card_wrapper">
                          <div class="barc_icon">
                            <img src="../../../../../assets/images/icon-dashboard/grant.svg" class="img-fluid" alt="">
                          </div>
                          <router-link :to="{ path: '/incentive-grant-service/barc/report/fund-distribution-report' }">
                            <div class="content">
                                <h6 class="title">{{ $t('incentDashboard.totalDisbursedGrant') }}</h6>
                                <h2 class="count count_1">{{ $n(tkConverToCrore(data.barc_disbursed_grant)) }} {{ $t('globalTrans.crore') }}</h2>
                            </div>
                          </router-link>
                        </div>
                      </b-card>
                    </b-col>
                  </b-row>
                  <b-row class="justify-content-center">
                    <b-col lg="8" md="8" sm="8" xs="12">
                      <div v-if="barcLoading">
                        <b-overlay :show="true"></b-overlay>
                      </div>
                      <div class="chart_wrapper" v-else>
                        <h5><b>{{ $t('incentDashboard.universtiyWiseStudent') }}</b></h5>
                        <apexchart class="chart-1 text-center px-5" id="vuechart-example" type="bar" height="400" :options="dataOption" :series="series"></apexchart>
                      </div>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { barc } from '../../api/routes'

export default {
    name: 'IncentiveBarc',
    data () {
      return {
        loading: false,
        barcLoading: false,
        search: {
          fiscal_year_id: 0
        },
        data: {},
        seriesData: [],
        labelList: []
      }
    },
    computed: {
        currentLocale: function () {
          return this.$i18n.locale
        },
        fiscalYearList: function () {
          return this.$store.state.commonObj.fiscalYearList
        },
        series: function () {
          return [{
            name: this.$t('sitePreference.total'),
            data: this.seriesData
          }]
        },
        dataOption () {
          return {
            chart: {
              id: 'vuechart-example',
              type: 'bar'
            },
            plotOptions: {
              bar: {
                barHeight: '50px',
                columnWidth: '30%',
                distributed: true,
                horizontal: false,
                dataLabels: {
                  position: 'bottom',
                  horizontalAlign: 'center'
                }
              }
            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val
              },
              offsetY: -1,
              style: {
                fontSize: '14px',
                colors: ['#fff']
              }
            },
            labels: this.labelList
          }
        }
    },
    created () {
      this.loadData()
    },
    mounted () {
      core.index()
    },
    methods: {
      searchData () {
        this.data = {}
        this.seriesData = []
        this.labelList = []
        this.loadData()
      },
      async loadData () {
        this.loading = true
        this.barcLoading = true
        const params = Object.assign({}, this.search)
        const response = await RestApi.getData(incentiveGrantServiceBaseUrl, barc, params)
        this.barcLoading = false
        this.loading = false
        if (response.success) {
          this.data = response.data
          this.getSeriesAndLabel(response.data)
        } else {
          this.data = {}
        }
      },
      getOrgName (orgId) {
        const org = this.$store.state.commonObj.organizationProfileList.find(org => org.value === orgId)
        return this.currentLocale === 'bn' ? org.text_bn : org.text_en
      },
      getSeriesAndLabel (data) {
        data.barc_versity_students.map(versity => {
          this.seriesData.push(versity.total_student)
          this.labelList.push(this.currentLocale === 'bn' ? versity.abbreviation_bn : versity.abbreviation)
        })
      },
      tkConverToCrore (amount) {
        return (amount / 10000000).toFixed(2)
      }
    }
}
</script>
