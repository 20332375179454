<template>
  <div class="incentive_dashboard_wrapper">
    <b-container fluid>
      <b-row>
        <b-col sm="12">
          <b-card class="iq-card main_card">
              <b-overlay :show="loading">
                <b-tabs justified>
                    <b-tab :title="getOrgName(8)">
                        <div class="org_filter_wrapper">
                            <b-row>
                                <b-col sm="3" class="mb-3">
                                    <label for="fiscal_year_id" class="form-label">{{$t('dae_grant_allocation_distribution.fiscal_year')}}</label>
                                    <b-form-select
                                        plain
                                        v-model="search.fiscal_year_id"
                                        :options="fiscalYearList"
                                        id="fiscal_year_id"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-col>
                                <b-col sm="3" class="mb-3">
                                    <label for="mill_info_id" class="form-label">{{ $t('bsri_config.mill_name') }}</label>
                                    <b-form-select
                                        plain
                                        v-model="search.mill_info_id"
                                        :options="millInfoList"
                                        id="mill_info_id"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-col>
                                <b-col sm="3" class="mb-3">
                                    <div class="btn_filter_wrapper">
                                        <button type="submit" class="btn btn-primary" @click="searchData">{{ $t('globalTrans.search') }}</button>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <b-row class="justify-content-md-center dae_card">
                          <b-col sm="3">
                            <b-card class="iq-card shadow_card card-1">
                              <div class="total_card_wrapper">
                                <div class="icon icon_1">
                                  <img src="../../../../../assets/images/icon-dashboard/farmer.svg" class="img-fluid" alt="">
                                </div>
                                <div class="content">
                                  <h5 class="title">{{ $t('incentDashboard.totalFarmer') }}</h5>
                                  <h2 class="count count_1">{{ $n(data.bsri_total_farmer) }}</h2>
                                </div>
                              </div>
                            </b-card>
                          </b-col>
                        </b-row>
                        <b-row class="justify-content-md-center bsri-card mt-4 pt-4">
                            <b-col sm="3">
                              <div class="card_item_wrapper total_farmer_wrapper">
                                  <div class="number_wrapper">
                                      <p class="number icon_1">
                                          <img src="../../../../../assets/images/icon-dashboard/farmer.svg" class="item_icon img-fluid">
                                      </p>
                                  </div>
                                  <div class="card_item">
                                      <p class="title">{{ $t('incentDashboard.farmerDemandCollected') }}</p>
                                      <h2 class="count production_count">{{ $n(data.bsri_demand_approved + data.bsri_demand_pending) }}</h2>
                                  </div>
                              </div>
                            </b-col>
                            <b-col sm="3">
                                <div class="card_item_wrapper demand_approved_wrapper">
                                  <div class="number_wrapper">
                                      <p class="number icon_3">
                                          <img src="../../../../../assets/images/icon-dashboard/approve.svg" class="item_icon img-fluid">
                                      </p>
                                  </div>
                                  <div class="card_item">
                                      <p class="title">{{ $t('incentDashboard.farDemandApproved') }}</p>
                                      <h2 class="count production_count">{{ $n(data.bsri_demand_approved) }}</h2>
                                  </div>
                              </div>
                            </b-col>
                            <b-col sm="3">
                                <div class="card_item_wrapper demand_collected_wrapper">
                                  <div class="number_wrapper">
                                      <p class="number icon_2">
                                          <img src="../../../../../assets/images/icon-dashboard/collection.svg" class="item_icon img-fluid">
                                      </p>
                                  </div>
                                  <div class="card_item">
                                      <p class="title">{{ $t('incentDashboard.farDemandCollected') }}</p>
                                      <h2 class="count production_count">{{ $n(data.bsri_demand_pending) }}</h2>
                                  </div>
                              </div>
                            </b-col>
                            <b-col sm="3">
                                <div class="card_item_wrapper disbursed_amount_wrapper">
                                  <div class="number_wrapper">
                                      <p class="number icon_4">
                                          <img src="../../../../../assets/images/icon-dashboard/amount.svg" class="item_icon img-fluid">
                                      </p>
                                  </div>
                                  <div class="card_item">
                                      <p class="title">{{ $t('incentDashboard.disburseSubsidyAmount') }}</p>
                                      <h2 class="count production_count">{{ $n(tkConverToCrore(data.bsri_disbursed_subsidy_amount)) }} {{ $t('globalTrans.crore') }}</h2>
                                  </div>
                              </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" md="6" sm="6" xs="12">
                              <div class="chart_wrapper">
                                  <h5><b>{{ $t('incentDashboard.methodLandQty') }}</b></h5>
                                  <apexchart class="chart-1 text-center px-5" id="vuechart-qty" type="bar" height="400" :options="landQtyOption" :series="landQtySeries"></apexchart>
                              </div>
                            </b-col>
                            <b-col lg="6" md="6" sm="6" xs="12">
                              <div class="chart_wrapper">
                                  <h5><b>{{ $t('incentDashboard.methodWiseSubsidyAmount') }}</b></h5>
                                  <apexchart class="chart-1 text-center px-5" id="vuechart-amount" type="bar" height="400" :options="landAmountOption" :series="landAmountSeries"></apexchart>
                              </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="8" offset-lg="2" md="8" offset-md="2" sm="12" xs="12">
                              <div class="chart_wrapper">
                                  <h5><b>{{ $t('incentDashboard.methodWiseLand') }}</b></h5>
                                  <apexchart v-if="isPiechart" type="pie" :options="pieOption" :series="pieSeriesData"></apexchart>
                              </div>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
              </b-overlay>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { bsri } from '../../api/routes'

export default {
    name: 'IncentiveDashboard',
    data () {
      return {
        loading: false,
        search: {
            fiscal_year_id: 0,
            mill_info_id: 0
        },
        data: {},
        bsriLoading: false,
        isPiechart: false,
        landQtySeriesData: [],
        landQtyLabels: [],
        landAmountSeriesData: [],
        landAmountLabels: [],
        pieSeriesData: [],
        pieLabels: []
      }
    },
    computed: {
        currentLocale: function () {
          return this.$i18n.locale
        },
        fiscalYearList: function () {
          return this.$store.state.commonObj.fiscalYearList
        },
        millInfoList: function () {
          return this.$store.state.incentiveGrant.commonObj.millInfoList
        },
        landQtySeries: function () {
          return [{
            name: this.$t('sitePreference.total'),
            data: this.landQtySeriesData
          }]
        },
        landQtyOption () {
          return {
            chart: {
              id: 'vuechart-qty',
              type: 'bar'
            },
            plotOptions: {
              bar: {
                barHeight: '50px',
                columnWidth: '60%',
                distributed: true,
                horizontal: false,
                dataLabels: {
                  position: 'bottom',
                  horizontalAlign: 'center'
                }
              }
            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val
              },
              offsetY: -1,
              style: {
                fontSize: '14px',
                colors: ['#fff']
              }
            },
            xaxis: {
              labels: {
                show: false
              }
            },
            labels: this.landQtyLabels
          }
        },
        landAmountSeries: function () {
          return [{
            name: this.$t('sitePreference.total'),
            data: this.landAmountSeriesData
          }]
        },
        landAmountOption () {
          return {
            chart: {
              id: 'vuechart-amount',
              type: 'bar'
            },
            plotOptions: {
              bar: {
                barHeight: '50px',
                columnWidth: '60%',
                distributed: true,
                horizontal: false,
                dataLabels: {
                  position: 'bottom',
                  horizontalAlign: 'center'
                }
              }
            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val
              },
              offsetY: -1,
              style: {
                fontSize: '14px',
                colors: ['#fff']
              }
            },
            xaxis: {
              labels: {
                show: false
              }
            },
            labels: this.landAmountLabels
          }
        },
        pieSeries: function () {
          return [{
            name: this.$t('sitePreference.total'),
            data: this.pieSeriesData
          }]
        },
        pieOption () {
          return {
            chart: {
              width: 380,
              type: 'pie'
            },
            labels: this.pieLabels,
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          }
        }
    },
    created () {
      this.landQtySeriesData = []
      this.landQtyLabels = []
      this.landAmountSeriesData = []
      this.landAmountLabels = []
      this.pieSeriesData = []
      this.pieLabels = []
      this.loadData()
    },
    methods: {
      series () {
        return this.pieSeriesData
      },
      searchData () {
        this.loadData()
      },
      async loadData () {
          this.loading = true
          this.bsriLoading = true
          const params = Object.assign({}, this.search)
          const response = await RestApi.getData(incentiveGrantServiceBaseUrl, bsri, params)
          this.loading = false
          this.bsriLoading = false
          if (response.success) {
              this.data = response.data
              this.methodLandSeriesAndLabel(response.data)
              this.methodAmountSeriesAndLabel(response.data)
              this.pieSeriesAndLabel(response.data)
              this.isPiechart = true
          } else {
              this.data = {}
          }
      },
      methodLandSeriesAndLabel (data) {
        data.bsri_method_land.map(method => {
            this.landQtySeriesData.push(parseFloat(method.land_quantity))
            this.landQtyLabels.push(this.currentLocale === 'bn' ? method.method_name_bn : method.method_name)
        })
      },
      methodAmountSeriesAndLabel (data) {
          data.bsri_method_amount.map(method => {
              this.landAmountSeriesData.push(this.numDifferentiation(method.total_amount))
              this.landAmountLabels.push(this.currentLocale === 'bn' ? method.method_name_bn : method.method_name)
          })
      },
      pieSeriesAndLabel (data) {
          data.bsri_method_amount.map(method => {
              this.pieSeriesData.push(parseFloat(method.total_amount))
              this.pieLabels.push(this.currentLocale === 'bn' ? method.method_name_bn : method.method_name)
          })
      },
      numDifferentiation (val) {
        return (val / 10000000).toFixed(2)
      },
      getOrgName (orgId) {
          const org = this.$store.state.commonObj.organizationProfileList.find(org => org.value === orgId)
          return this.currentLocale === 'bn' ? org.text_bn : org.text_en
      },
      tkConverToCrore (amount) {
        return (amount / 10000000).toFixed(2)
      }
    },
    mounted () {
        core.index()
    }
}
</script>
