<template>
  <div class="incentive_dashboard_wrapper dae">
    <b-container fluid>
      <b-row>
        <b-col sm="12">
          <b-card class="iq-card main_card">
            <b-overlay :show="loading">
              <b-tabs justified>
                <b-tab :title="getOrgName(2)">
                  <div class="org_filter_wrapper">
                    <b-row>
                      <b-col sm="3" class="mb-3">
                        <label for="fiscal_year_id" class="form-label">{{$t('dae_grant_allocation_distribution.fiscal_year')}}</label>
                        <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalYearList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-col>
                      <b-col sm="3" class="mb-3">
                        <label for="project_id" class="form-label">{{ $t('dae_config.project_name') }}</label>
                        <b-form-select
                          plain
                          v-model="search.project_id"
                          :options="projectList"
                          id="project_id"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-col>
                      <b-col sm="3" class="mb-3">
                        <label for="division_id" class="form-label">{{ $t('globalTrans.division') }}</label>
                        <b-form-select
                          plain
                          v-model="search.division_id"
                          :options="divisionList"
                          id="division_id"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-col>
                      <b-col sm="3" class="mb-3">
                        <label for="district_id" class="form-label">{{ $t('globalTrans.district') }}</label>
                        <b-form-select
                          plain
                          v-model="search.district_id"
                          :options="districtList"
                          id="district_id"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-col>
                      <b-col sm="3" class="mb-3">
                        <label for="upazilla_id" class="form-label">{{ $t('globalTrans.upazila') }}</label>
                        <b-form-select
                          plain
                          v-model="search.upazilla_id"
                          :options="upazillaList"
                          id="upazilla_id"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-col>
                      <b-col sm="3" class="mb-3">
                        <div class="btn_filter_wrapper">
                          <button type="submit" class="btn btn-primary" @click="searchData">{{ $t('globalTrans.search') }}</button>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <b-row class="justify-content-md-center dae_card">
                    <b-col sm="3">
                      <b-card class="iq-card shadow_card card-1">
                        <div class="total_card_wrapper">
                          <div class="icon icon_1">
                            <img src="../../../../../assets/images/icon-dashboard/farmer.svg" class="img-fluid" alt="">
                          </div>
                          <div class="content">
                            <h5 class="title">{{ $t('incentDashboard.totalFarmer') }}</h5>
                            <h2 class="count count_1">{{ $n(data.dae_subsidy_total_farmer) }}</h2>
                          </div>
                        </div>
                      </b-card>
                    </b-col>
                  </b-row>
                  <fieldset class="subsidy_wrapper">
                    <legend class="legend">{{ $t('incentDashboard.mechineryPurchaseSubsidy') }}:</legend>
                    <b-row class="justify-content-md-center dae_card">
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-1">
                          <div class="total_card_wrapper">
                            <div class="icon icon_1">
                              <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.totalApp') }}</h6>
                              <h2 class="count count_1">{{ $n(data.dae_subsidy_apps) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-3">
                          <div class="total_card_wrapper">
                            <div class="icon icon_3">
                              <img src="../../../../../assets/images/icon-dashboard/approve.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.approvedApp') }}</h6>
                              <h2 class="count count_3">{{ $n(data.dae_subsidy_app_approved) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-2">
                          <div class="total_card_wrapper">
                            <div class="icon icon_2">
                              <img src="../../../../../assets/images/icon-dashboard/painding-application.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.pendingApp') }}</h6>
                              <h2 class="count count_2">{{ $n(data.dae_subsidy_app_pending) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-4">
                          <div class="total_card_wrapper">
                            <div class="icon icon_4">
                              <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.distributed') }}</h6>
                              <h2 class="count count_4">{{ $n(data.dae_subsidy_app_distributed) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-4">
                          <div class="total_card_wrapper">
                            <div class="icon icon_4">
                              <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.rejectApp') }}</h6>
                              <h2 class="count count_4">{{ $n(data.dae_subsidy_app_rejected) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <b-row class="justify-content-md-center">
                    <b-col lg="8" md="8" sm="8" xs="12">
                      <div class="chart_wrapper">
                        <h4><b>{{ $t('incentDashboard.machineries') }}</b></h4>
                        <apexchart class="chart-1 text-center px-5" id="vuechart-mechinary" type="bar" height="400" :options="landMechinaryOption" :series="landMechinarySeries"></apexchart>
                      </div>
                    </b-col>
                  </b-row>
                  <fieldset class="subsidy_wrapper">
                    <legend class="legend">{{ $t('incentDashboard.incentive') }}:</legend>
                    <b-row class="justify-content-md-center dae_card">
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-1">
                          <div class="total_card_wrapper">
                            <div class="icon icon_1" style="padding: 6px 10px;">
                              <img src="../../../../../assets/images/icon-dashboard/collection.svg" style="width: 40px;height: 40px;" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.farmerDemandCollected') }}</h6>
                              <h2 class="count count_1">{{ $n(data.dae_incen_demand_collected) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-3">
                          <div class="total_card_wrapper">
                            <div class="icon icon_3">
                              <img src="../../../../../assets/images/icon-dashboard/approve.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.approvedFarmer') }}</h6>
                              <h2 class="count count_3">{{ $n(data.dae_incen_demand_approved) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-2">
                          <div class="total_card_wrapper">
                            <div class="icon icon_2">
                              <img src="../../../../../assets/images/icon-dashboard/rejected-farmer.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.rejectedFarmer') }}</h6>
                              <h2 class="count count_2">{{ $n(data.dae_incen_demand_rejected) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-4">
                          <div class="total_card_wrapper">
                            <div class="icon icon_4">
                              <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.distributed') }}</h6>
                              <h2 class="count count_4">{{ $n(data.dae_incen_demand_distributed) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-4">
                          <div class="total_card_wrapper">
                            <div class="icon icon_4">
                              <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.pendingFarmer') }}</h6>
                              <h2 class="count count_4">{{ $n(data.dae_incen_demand_pending) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="subsidy_wrapper mt-3">
                    <legend class="legend">{{ $t('incentDashboard.rehabilitation') }}:</legend>
                    <b-row class="justify-content-md-center dae_card">
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-1">
                          <div class="total_card_wrapper">
                            <div class="icon icon_1" style="padding: 6px 10px;">
                              <img src="../../../../../assets/images/icon-dashboard/collection.svg" style="width: 40px;height: 40px" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.farmerDemandCollected') }}</h6>
                              <h2 class="count count_1">{{ $n(data.dae_rb_demand_collected) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-3">
                          <div class="total_card_wrapper">
                            <div class="icon icon_3">
                              <img src="../../../../../assets/images/icon-dashboard/approve.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.approvedFarmer') }}</h6>
                              <h2 class="count count_3">{{ $n(data.dae_rb_demand_approved) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-2">
                          <div class="total_card_wrapper">
                            <div class="icon icon_2">
                              <img src="../../../../../assets/images/icon-dashboard/rejected-farmer.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.rejectedFarmer') }}</h6>
                              <h2 class="count count_2">{{ $n(data.dae_rb_demand_rejected) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-4">
                          <div class="total_card_wrapper">
                            <div class="icon icon_4">
                              <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.distributed') }}</h6>
                              <h2 class="count count_4">{{ $n(data.dae_rb_demand_distributed) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-4">
                          <div class="total_card_wrapper">
                            <div class="icon icon_4">
                              <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.pendingFarmer') }}</h6>
                              <h2 class="count count_4">{{ $n(data.dae_rb_demand_pending) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <b-row>
                    <b-col lg="6" md="6" sm="6" xs="12">
                      <div class="chart_wrapper">
                        <h4><b>{{ $t('incentDashboard.distributedCrop') }}</b></h4>
                        <apexchart class="chart-1 text-center px-5" id="vuechart-crop" type="bar" height="400" :options="cropOption" :series="cropSeries"></apexchart>
                      </div>
                    </b-col>
                    <b-col lg="6" md="6" sm="6" xs="12">
                      <div class="chart_wrapper">
                        <h4><b>{{ $t('incentDashboard.distributedMaterial') }}</b></h4>
                        <apexchart class="chart-1 text-center px-5" id="vuechart-material" type="bar" height="400" :options="materialOption" :series="materialSeries"></apexchart>
                      </div>
                    </b-col>
                  </b-row>
                  <fieldset class="subsidy_wrapper">
                    <legend class="legend">{{ $t('incentDashboard.financialAid') }}:</legend>
                    <b-row class="justify-content-md-center dae_card">
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-1">
                          <div class="total_card_wrapper">
                            <div class="icon icon_1">
                              <img src="../../../../../assets/images/icon-dashboard/farmer.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.farmerSelected') }}</h6>
                              <h2 class="count count_1">{{ $n(data.dae_fa_farmer_selected) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-3">
                          <div class="total_card_wrapper">
                            <div class="icon icon_3">
                              <img src="../../../../../assets/images/icon-dashboard/approve.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.approved') }}</h6>
                              <h2 class="count count_3">{{ $n(data.dae_fa_farmer_approved) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-2">
                          <div class="total_card_wrapper">
                            <div class="icon icon_2">
                              <img src="../../../../../assets/images/icon-dashboard/rejected-farmer.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.rejectedFarmer') }}</h6>
                              <h2 class="count count_2">{{ $n(data.dae_fa_farmer_rejected) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-4">
                          <div class="total_card_wrapper">
                            <div class="icon icon_4">
                              <img src="../../../../../assets/images/icon-dashboard/amount.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.disbursedFarmer') }}</h6>
                              <h2 class="count count_4">{{ $n(data.dae_fa_farmer_disbursed) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="3">
                        <b-card class="iq-card shadow_card card-4">
                          <div class="total_card_wrapper">
                            <div class="icon icon_4">
                              <img src="../../../../../assets/images/icon-dashboard/amount.svg" class="img-fluid" alt="">
                            </div>
                            <div class="content">
                              <h6 class="title">{{ $t('incentDashboard.disbursedAmount') }}</h6>
                              <h2 class="count count_4">{{ $n(data.dae_fa_distributed_amount) }}</h2>
                            </div>
                          </div>
                        </b-card>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-tab>
              </b-tabs>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { dae } from '../../api/routes'

export default {
    name: 'IncentiveDaeDashboard',
    data () {
      return {
        loading: false,
        daeLoading: false,
        search: {
          fiscal_year_id: 0,
          project_id: 0,
          division_id: 0,
          district_id: 0,
          upazilla_id: 0
        },
        data: {},
        districtList: [],
        upazillaList: [],
        landMechinarySeriesData: [],
        landMechinaryLabels: [],
        cropSeriesData: [],
        cropLabels: [],
        materialSeriesData: [],
        materialLabels: []
      }
    },
    watch: {
      'search.division_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.districtList = this.getDistrictList(newVal)
        }
      },
      'search.district_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.upazillaList = this.getupazillaList(newVal)
        }
      }
    },
    computed: {
      currentLocale: function () {
        return this.$i18n.locale
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList
      },
      divisionList: function () {
        return this.$store.state.commonObj.divisionList
      },
      projectList: function () {
        return this.$store.state.incentiveGrant.commonObj.projectList
      },
      landMechinarySeries: function () {
        return [{
          name: this.$t('sitePreference.total'),
          data: this.landMechinarySeriesData
        }]
      },
      landMechinaryOption () {
        return {
          chart: {
            id: 'vuechart-mechinary',
            type: 'bar'
          },
          plotOptions: {
            bar: {
              barHeight: '50px',
              columnWidth: '30%',
              distributed: true,
              horizontal: false,
              dataLabels: {
                position: 'bottom',
                horizontalAlign: 'center'
              }
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val
            },
            offsetY: -1,
            style: {
              fontSize: '14px',
              colors: ['#fff']
            }
          },
          labels: this.landMechinaryLabels
        }
      },
      cropSeries: function () {
        return [{
          name: this.$t('sitePreference.total'),
          data: this.cropSeriesData
        }]
      },
      cropOption () {
        return {
          chart: {
            id: 'vuechart-crop',
            type: 'bar'
          },
          plotOptions: {
            bar: {
              barHeight: '50px',
              columnWidth: '60%',
              distributed: true,
              horizontal: false,
              dataLabels: {
                position: 'bottom',
                horizontalAlign: 'center'
              }
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val
            },
            offsetY: -1,
            style: {
              fontSize: '14px',
              colors: ['#fff']
            }
          },
          labels: this.cropLabels
        }
      },
      materialSeries: function () {
        return [{
          name: this.$t('sitePreference.total'),
          data: this.materialSeriesData
        }]
      },
      materialOption () {
        return {
          chart: {
            id: 'vuechart-material',
            type: 'bar'
          },
          plotOptions: {
            bar: {
              barHeight: '50px',
              columnWidth: '60%',
              distributed: true,
              horizontal: false,
              dataLabels: {
                position: 'bottom',
                horizontalAlign: 'center'
              }
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val
            },
            offsetY: -1,
            style: {
              fontSize: '14px',
              colors: ['#fff']
            }
          },
          labels: this.materialLabels
        }
      }
    },
    created () {
      this.loadData()
    },
    mounted () {
      core.index()
    },
    methods: {
      searchData () {
        this.data = {}
        this.landMechinarySeriesData = []
        this.landMechinaryLabels = []
        this.cropSeriesData = []
        this.cropLabels = []
        this.materialSeriesData = []
        this.materialLabels = []
        this.loadData()
      },
      async loadData () {
        this.loading = true
        this.daeLoading = true
        const params = Object.assign({}, this.search)
        const response = await RestApi.getData(incentiveGrantServiceBaseUrl, dae, params)
        this.daeLoading = false
        this.loading = false
        if (response.success) {
          this.data = response.data
          this.mechinerySeriesAndLabel(response.data)
          this.cropSeriesAndLabel(response.data)
          this.materialSeriesAndLabel(response.data)
        } else {
          this.data = {}
        }
      },
      mechinerySeriesAndLabel (data) {
        data.dae_distributed_instruments.map(instrument => {
          this.landMechinarySeriesData.push(parseInt(instrument.quantity))
          this.landMechinaryLabels.push(this.currentLocale === 'bn' ? instrument.name_bn : instrument.name)
        })
      },
      cropSeriesAndLabel (data) {
        data.dae_distributed_crops.map(item => {
          this.cropSeriesData.push(parseInt(item.quantity / 1000))
          this.cropLabels.push(this.currentLocale === 'bn' ? item.crop_name_bn : item.crop_name)
        })
      },
      materialSeriesAndLabel (data) {
        data.dae_distributed_materials.map(item => {
          this.materialSeriesData.push(parseInt(item.quantity / 1000))
          this.materialLabels.push(this.currentLocale === 'bn' ? item.name_bn : item.name)
        })
      },
      getOrgName (orgId) {
        const org = this.$store.state.commonObj.organizationProfileList.find(org => org.value === orgId)
        return this.currentLocale === 'bn' ? org.text_bn : org.text_en
      },
      getDistrictList (divisionId = null) {
        const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        if (divisionId) {
          return districtList.filter(district => district.division_id === divisionId)
        }
        return districtList
      },
      getupazillaList (districtId = null) {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
        if (districtId) {
          return upazilaList.filter(upazila => upazila.district_id === districtId)
        }
        return upazilaList
      }
    }
}
</script>
