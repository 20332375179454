<template>
  <div class="incentive_dashboard_wrapper">
    <b-container fluid>
      <b-row>
        <b-col sm="12">
          <b-card class="iq-card">
            <b-overlay :show="loading">
              <b-tabs justified>
                <b-tab :title="$t('incentDashboard.allOrg')" active v-if="!isOrgAdmin && roleId === 1" @click="showTab(0)">
                  <div class="incentive_dashboard_wrapper bsri">
                    <b-card class="iq-card main_card">
                      <b-overlay :show="loading">
                        <b-tabs justified>
                            <b-tab :title="getOrgName(8)">
                              <b-row class="justify-content-md-center dae_card">
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-1">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_1">
                                        <img src="../../../../../assets/images/icon-dashboard/farmer.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h5 class="title">{{ $t('incentDashboard.totalFarmer') }}</h5>
                                        <h2 class="count count_1">{{ $n(data.bsri_total_farmer) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                              </b-row>
                                <b-row class="bsri-card mt-4">
                                    <b-col sm="3">
                                      <div class="card_item_wrapper total_farmer_wrapper">
                                          <div class="number_wrapper">
                                              <p class="number icon_1">
                                                  <img src="../../../../../assets/images/icon-dashboard/farmer.svg" class="item_icon img-fluid">
                                              </p>
                                          </div>
                                          <router-link :to="{ path: '/incentive-grant-service/bsri/demand-distribution/demand-collection' }">
                                            <div class="card_item">
                                                <p class="title">{{ $t('incentDashboard.farmerDemandCollected') }}</p>
                                                <h2 class="count production_count">{{ $n(data.bsri_demand_approved + data.bsri_demand_pending) }}</h2>
                                            </div>
                                          </router-link>
                                      </div>
                                    </b-col>
                                    <b-col sm="3">
                                      <div class="card_item_wrapper demand_approved_wrapper">
                                        <div class="number_wrapper">
                                            <p class="number icon_3">
                                                <img src="../../../../../assets/images/icon-dashboard/approve.svg" class="item_icon img-fluid">
                                            </p>
                                        </div>
                                        <router-link :to="{ path: '/incentive-grant-service/bsri/demand-distribution/subsidize-far-approval-list' }">
                                            <div class="card_item">
                                                <p class="title">{{ $t('incentDashboard.farDemandApproved') }}</p>
                                                <h2 class="count production_count">{{ $n(data.bsri_demand_approved) }}</h2>
                                            </div>
                                        </router-link>
                                      </div>
                                    </b-col>
                                    <b-col sm="3">
                                      <div class="card_item_wrapper demand_collected_wrapper">
                                        <div class="number_wrapper">
                                            <p class="number icon_2">
                                                <img src="../../../../../assets/images/icon-dashboard/collection.svg" class="item_icon img-fluid">
                                            </p>
                                        </div>
                                        <div class="card_item">
                                            <p class="title">{{ $t('incentDashboard.farDemandCollected') }}</p>
                                            <h2 class="count production_count">{{ $n(data.bsri_demand_pending) }}</h2>
                                        </div>
                                      </div>
                                    </b-col>
                                    <b-col sm="3">
                                      <div class="card_item_wrapper disbursed_amount_wrapper">
                                        <div class="number_wrapper">
                                            <p class="number icon_4">
                                                <img src="../../../../../assets/images/icon-dashboard/amount.svg" class="item_icon img-fluid">
                                            </p>
                                        </div>
                                        <router-link :to="{ path: '/incentive-grant-service/bsri/demand-distribution/distribution-registrar' }">
                                            <div class="card_item">
                                                <p class="title">{{ $t('incentDashboard.disburseSubsidyAmount') }}</p>
                                                <h2 class="count production_count">{{ $n(tkConverToCrore(data.bsri_disbursed_subsidy_amount)) }} {{ $t('globalTrans.crore') }}</h2>
                                            </div>
                                        </router-link>
                                      </div>
                                    </b-col>
                                </b-row>
                            </b-tab>
                        </b-tabs>
                      </b-overlay>
                  </b-card>
                  </div>
                  <div class="incentive_dashboard_wrapper dae">
                    <b-card class="iq-card main_card">
                      <b-overlay :show="loading">
                        <b-tabs justified>
                          <b-tab :title="getOrgName(2)">
                            <b-row class="justify-content-center dae_card">
                              <b-col sm="3">
                                <b-card class="iq-card shadow_card card-1">
                                  <div class="total_card_wrapper">
                                    <div class="icon icon_1">
                                      <img src="../../../../../assets/images/icon-dashboard/farmer.svg" class="img-fluid" alt="">
                                    </div>
                                    <div class="content">
                                      <h5 class="title">{{ $t('incentDashboard.totalFarmer') }}</h5>
                                      <h2 class="count count_1">{{ $n(data.dae_subsidy_total_farmer) }}</h2>
                                    </div>
                                  </div>
                                </b-card>
                              </b-col>
                            </b-row>
                            <fieldset class="subsidy_wrapper">
                              <legend class="legend">{{ $t('incentDashboard.mechineryPurchaseSubsidy') }}:</legend>
                              <b-row class="justify-content-md-center dae_card">
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-1">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_1">
                                        <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.totalApp') }}</h6>
                                        <h2 class="count count_1">{{ $n(data.dae_subsidy_apps) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-3">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_3">
                                        <img src="../../../../../assets/images/icon-dashboard/approve.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.approvedApp') }}</h6>
                                        <h2 class="count count_3">{{ $n(data.dae_subsidy_app_approved) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-2">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_2">
                                        <img src="../../../../../assets/images/icon-dashboard/painding-application.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.pendingApp') }}</h6>
                                        <h2 class="count count_2">{{ $n(data.dae_subsidy_app_pending) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-4">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_4">
                                        <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.distributed') }}</h6>
                                        <h2 class="count count_4">{{ $n(data.dae_subsidy_app_distributed) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-4">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_4">
                                        <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.rejectApp') }}</h6>
                                        <h2 class="count count_4">{{ $n(data.dae_subsidy_app_rejected) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                              </b-row>
                            </fieldset>
                            <fieldset class="subsidy_wrapper">
                              <legend class="legend">{{ $t('incentDashboard.incentive') }}:</legend>
                              <b-row class="justify-content-md-center dae_card">
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-1">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_1">
                                        <img src="../../../../../assets/images/icon-dashboard/collection.svg" style="width: 40px" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.farmerDemandCollected') }}</h6>
                                        <h2 class="count count_1">{{ $n(data.dae_incen_demand_collected) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-3">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_3">
                                        <img src="../../../../../assets/images/icon-dashboard/approve.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.approvedFarmer') }}</h6>
                                        <h2 class="count count_3">{{ $n(data.dae_incen_demand_approved) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-2">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_2">
                                        <img src="../../../../../assets/images/icon-dashboard/rejected-farmer.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.rejectedFarmer') }}</h6>
                                        <h2 class="count count_2">{{ $n(data.dae_incen_demand_rejected) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-4">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_4">
                                        <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.distributed') }}</h6>
                                        <h2 class="count count_4">{{ $n(data.dae_incen_demand_distributed) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-4">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_4">
                                        <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.pendingFarmer') }}</h6>
                                        <h2 class="count count_4">{{ $n(data.dae_incen_demand_pending) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                              </b-row>
                            </fieldset>
                            <fieldset class="subsidy_wrapper mt-3">
                              <legend class="legend">{{ $t('incentDashboard.rehabilitation') }}:</legend>
                              <b-row class="justify-content-md-center dae_card">
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-1">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_1">
                                        <img src="../../../../../assets/images/icon-dashboard/collection.svg" style="width: 40px" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.farmerDemandCollected') }}</h6>
                                        <h2 class="count count_1">{{ $n(data.dae_rb_demand_collected) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-3">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_3">
                                        <img src="../../../../../assets/images/icon-dashboard/approve.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.approvedFarmer') }}</h6>
                                        <h2 class="count count_3">{{ $n(data.dae_rb_demand_approved) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-2">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_2">
                                        <img src="../../../../../assets/images/icon-dashboard/rejected-farmer.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.rejectedFarmer') }}</h6>
                                        <h2 class="count count_2">{{ $n(data.dae_rb_demand_rejected) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-4">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_4">
                                        <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.distributed') }}</h6>
                                        <h2 class="count count_4">{{ $n(data.dae_rb_demand_distributed) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-4">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_4">
                                        <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.pendingFarmer') }}</h6>
                                        <h2 class="count count_4">{{ $n(data.dae_rb_demand_pending) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                              </b-row>
                            </fieldset>
                            <fieldset class="subsidy_wrapper">
                              <legend class="legend">{{ $t('incentDashboard.financialAid') }}:</legend>
                              <b-row class="justify-content-md-center dae_card">
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-1">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_1">
                                        <img src="../../../../../assets/images/icon-dashboard/farmer.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.farmerSelected') }}</h6>
                                        <h2 class="count count_1">{{ $n(data.dae_fa_farmer_selected) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-3">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_3">
                                        <img src="../../../../../assets/images/icon-dashboard/approve.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.approved') }}</h6>
                                        <h2 class="count count_3">{{ $n(data.dae_fa_farmer_approved) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-2">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_2">
                                        <img src="../../../../../assets/images/icon-dashboard/rejected-farmer.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.rejectedFarmer') }}</h6>
                                        <h2 class="count count_2">{{ $n(data.dae_fa_farmer_rejected) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-4">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_4">
                                        <img src="../../../../../assets/images/icon-dashboard/amount.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.disbursedFarmer') }}</h6>
                                        <h2 class="count count_4">{{ $n(data.dae_fa_farmer_disbursed) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                                <b-col sm="3">
                                  <b-card class="iq-card shadow_card card-4">
                                    <div class="total_card_wrapper">
                                      <div class="icon icon_4">
                                        <img src="../../../../../assets/images/icon-dashboard/amount.svg" class="img-fluid" alt="">
                                      </div>
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.disbursedAmount') }}</h6>
                                        <h2 class="count count_4">{{ $n(data.dae_fa_distributed_amount) }}</h2>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col>
                              </b-row>
                            </fieldset>
                          </b-tab>
                        </b-tabs>
                      </b-overlay>
                    </b-card>
                  </div>
                  <div class="incentive_dashboard_wrapper barc">
                    <b-card class="iq-card main_card">
                      <b-overlay :show="loading">
                        <b-tabs justified>
                          <b-tab :title="getOrgName(4)">
                            <b-row class="justify-content-md-center barc_card">
                              <b-col sm="3">
                                <b-card class="iq-card shadow_card card-1">
                                  <div class="total_card_wrapper">
                                    <div class="barc_icon">
                                      <img src="../../../../../assets/images/icon-dashboard/total-application.svg" class="img-fluid" alt="">
                                    </div>
                                    <router-link :to="{ path: '/incentive-grant-service/barc/report/grant-application-status-report' }">
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.totalApp') }}</h6>
                                        <h2 class="count count_1">{{ $n(data.barc_total_app) }}</h2>
                                      </div>
                                    </router-link>
                                  </div>
                                </b-card>
                              </b-col>
                              <b-col sm="3">
                                <b-card class="iq-card shadow_card card-3">
                                  <div class="total_card_wrapper">
                                    <div class="barc_icon">
                                      <img src="../../../../../assets/images/icon-dashboard/approve.svg" class="img-fluid" alt="">
                                    </div>
                                    <router-link :to="{ path: '/incentive-grant-service/barc/report/approval-application-report' }">
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.approvedApp') }}</h6>
                                        <h2 class="count count_3">{{ $n(data.barc_app_approved) }}</h2>
                                      </div>
                                    </router-link>
                                  </div>
                                </b-card>
                              </b-col>
                              <b-col sm="3">
                                <b-card class="iq-card shadow_card card-2">
                                  <div class="total_card_wrapper">
                                    <div class="barc_icon">
                                      <img src="../../../../../assets/images/icon-dashboard/painding-application.svg" class="img-fluid" alt="">
                                    </div>
                                    <router-link :to="{ path: '/incentive-grant-service/barc/report/grant-application-status-report', query: {status: 5} }">
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.pendingApp') }}</h6>
                                        <h2 class="count count_2">{{ $n(data.barc_app_pending) }}</h2>
                                      </div>
                                    </router-link>
                                  </div>
                                </b-card>
                              </b-col>
                              <b-col sm="3">
                                <b-card class="iq-card shadow_card card-4">
                                  <div class="total_card_wrapper">
                                    <div class="barc_icon">
                                      <img src="../../../../../assets/images/icon-dashboard/rejected-farmer.svg" class="img-fluid" alt="">
                                    </div>
                                    <router-link :to="{ path: '/incentive-grant-service/barc/report/grant-application-status-report', query: {status: 3} }">
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.rejectApp') }}</h6>
                                        <h2 class="count count_4">{{ $n(data.barc_app_rejected) }}</h2>
                                      </div>
                                    </router-link>
                                  </div>
                                </b-card>
                              </b-col>
                              <b-col sm="3">
                                <b-card class="iq-card shadow_card card-1">
                                  <div class="total_card_wrapper">
                                    <div class="barc_icon">
                                      <img src="../../../../../assets/images/icon-dashboard/grant.svg" class="img-fluid" alt="">
                                    </div>
                                    <router-link :to="{ path: '/incentive-grant-service/barc/report/fund-distribution-report' }">
                                      <div class="content">
                                        <h6 class="title">{{ $t('incentDashboard.totalDisbursedGrant') }}</h6>
                                        <h2 class="count count_1">{{ $n(tkConverToCrore(data.barc_disbursed_grant)) }} {{ $t('globalTrans.crore') }}</h2>
                                      </div>
                                    </router-link>
                                  </div>
                                </b-card>
                              </b-col>
                            </b-row>
                          </b-tab>
                        </b-tabs>
                      </b-overlay>
                    </b-card>
                  </div>
                </b-tab>
                <b-tab :title="$t('incentDashboard.bsri')" v-if="isSuperAdmin || (isOrgAdmin && orgId === 8)" @click="showTab(1)">
                  <bsri v-if="bsriTabLoad"></bsri>
                </b-tab>
                <b-tab :title="$t('incentDashboard.dae')" v-if="isSuperAdmin || (isOrgAdmin && orgId === 2)" @click="showTab(2)">
                  <dae v-if="daeTabLoad"></dae>
                </b-tab>
                <b-tab :title="$t('incentDashboard.barc')" v-if="isSuperAdmin || (isOrgAdmin && orgId === 4)" @click="showTab(3)">
                  <barc  v-if="barcTabLoad"></barc>
                </b-tab>
              </b-tabs>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { allOrgApi } from '../../api/routes'
import barc from './barc'
import dae from './dae'
import bsri from './bsri'

export default {
    name: 'IrrigationDashboard',
    components: {
      barc,
      dae,
      bsri
    },
    data () {
      return {
        roleId: '',
        isSuperAdmin: false,
        isOrgAdmin: false,
        orgId: 0,
        loading: false,
        data: {},
        daeTabLoad: false,
        bsriTabLoad: false,
        barcTabLoad: false
      }
    },
    computed: {
      currentLocale: function () {
        return this.$i18n.locale
      },
      authUser: function () {
        return this.$store.state.Auth.authUser
      }
    },
    created () {
      this.getCurrentUser(this.authUser)
      if (this.authUser.role_id === 1) {
        this.loadData()
      }
    },
    mounted () {
      core.index()
    },
    methods: {
      getCurrentUser (authUser) {
        this.roleId = authUser.role_id
        if (authUser.role_id === 1 && authUser.is_org_admin === 0) {
          this.isSuperAdmin = true
          this.orgId = authUser.org_id
        } else if (authUser.role_id === 0 && authUser.is_org_admin === 1) {
          this.isOrgAdmin = true
          this.orgId = authUser.org_id
          if (this.orgId === 4) {
            this.showTab(3) // BARC
          } else if (this.orgId === 8) {
            this.showTab(1) // BSRI
          } else if (this.orgId === 2) {
            this.showTab(2) // DAE
          }
        } else {
          this.isSuperAdmin = false
          this.isOrgAdmin = false
        }
      },
      async loadData () {
        this.loading = true
        const response = await RestApi.getData(incentiveGrantServiceBaseUrl, allOrgApi)
        this.loading = false
        if (response.success) {
          this.data = response.data
        } else {
          this.data = {}
        }
      },
      getOrgName (orgId) {
        const org = this.$store.state.commonObj.organizationProfileList.find(org => org.value === orgId)
        return this.currentLocale === 'bn' ? org.text_bn : org.text_en
      },
      tkConverToCrore (amount) {
        return (amount / 10000000).toFixed(2)
      },
      showTab (tabId) {
        if (tabId === 1) {
          this.bsriTabLoad = true
        } else if (tabId === 2) {
          this.daeTabLoad = true
        } else if (tabId === 3) {
          this.barcTabLoad = true
        } else {
          this.bsriTabLoad = false
          this.daeTabLoad = false
          this.barcTabLoad = false
        }
      }
    }
}
</script>
